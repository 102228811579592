import React, { lazy } from 'react'

const ClaimCompanySetting = lazy(() =>
  import('./ClaimModule/CompanySetting/ClaimCompanySetting').then(module => ({
    default: module.ClaimCompanySetting,
  }))
)

const ClaimJobGradePolicyMatrixListing = lazy(() =>
  import(
    './ClaimModule/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixListing'
  ).then(module => ({
    default: module.JobGradePolicyMatrixListing,
  }))
)

const ClaimTypePolicyMatrixListing = lazy(() =>
  import(
    './ClaimModule/CompanySetting/ClaimTypePolicyMatrix/ClaimTypeListing'
  ).then(module => ({
    default: module.ClaimTypeListing,
  }))
)
const ClaimTypePolicyMatrixForm = lazy(() =>
  import(
    './ClaimModule/CompanySetting/ClaimTypePolicyMatrix/ClaimTypePolicyMatrixForm'
  ).then(module => ({
    default: module.ClaimTypePolicyMatrixForm,
  }))
)

const ClaimJobGradePolicyMatrixForm = lazy(() =>
  import(
    './ClaimModule/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixForm'
  ).then(module => ({
    default: module.JobGradePolicyMatrixForm,
  }))
)

const ClaimSetting = lazy(() =>
  import('./ClaimModule/CompanySetting/ClaimSetting/ClaimSetting').then(
    module => ({
      default: module.ClaimSetting,
    })
  )
)

const ExpenseAccountsListing = lazy(() =>
  import(
    './ClaimModule/CompanySetting/ExpenseAccounts/ExpenseAccountsListing'
  ).then(module => ({
    default: module.ExpenseAccountsListing,
  }))
)

const ClaimApprovalPolicyAssignment = lazy(() =>
  import(
    './ClaimModule/CompanySetting/ClaimApprovalPolicyAssignment/ClaimApprovalPolicyAssignment'
  ).then(module => ({
    default: module.ClaimApprovalPolicyAssignment,
  }))
)

const GLAssignmentForm = lazy(() =>
  import(
    './ClaimModule/CompanySetting/GLMappingAssignment/GLMappingAssignmentForm'
  ).then(module => ({
    default: module.GLMappingAssignmentForm,
  }))
)

const ClaimManagementListing = lazy(() =>
  import('./ClaimModule/ClaimManagementListing').then(module => ({
    default: module.ClaimManagementListing,
  }))
)

const ClaimPoolListing = lazy(() =>
  import('./ClaimModule/CompanySetting/ClaimPool/ClaimPoolListing').then(
    module => ({
      default: module.ClaimPoolListing,
    })
  )
)

const ClaimPoolForm = lazy(() =>
  import('./ClaimModule/CompanySetting/ClaimPool/ClaimPoolForm').then(
    module => ({
      default: module.ClaimPoolForm,
    })
  )
)


// -----------------------------------------------------------------------------------------

const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <ClaimManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/ClaimModule/CompanySetting/ClaimCompanySetting',
    },
    component: <ClaimCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixListing',
    },
    component: <ClaimJobGradePolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/ClaimTypePolicyMatrix/ClaimTypeListing',
    },
    component: <ClaimTypePolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/ClaimTypePolicyMatrix/ClaimTypePolicyMatrixForm',
    },
    component: <ClaimTypePolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixForm',
    },
    component: <ClaimJobGradePolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/ClaimModule/CompanySetting/ClaimSetting/ClaimSetting',
    },
    component: <ClaimSetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/ExpenseAccounts/ExpenseAccountsListing',
    },
    component: <ExpenseAccountsListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/ClaimModule/CompanySetting/ClaimApprovalPolicyAssignment/ClaimApprovalPolicyAssignment',
    },
    component: <ClaimApprovalPolicyAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/ClaimModule/CompanySetting/GLAssignment/GLAssignmentForm',
    },
    component: <GLAssignmentForm />,
  },
  {
    props: { exact: true, path: '/ClaimManagementListing' },
    component: <ClaimManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/ClaimModule/CompanySetting/ClaimPool/ClaimPoolListing',
    },
    component: <ClaimPoolListing />,
  },
  {
    props: {
      exact: true,
      path: '/ClaimModule/CompanySetting/ClaimPool/ClaimPoolForm',
    },
    component: <ClaimPoolForm />,
  },
]

export default HomeRoutes
